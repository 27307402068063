import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import axios from "axios";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const BasicDatatable = () => {
  const { t } = useTranslation();
  const { CurrencySymbol } = GlobalSet();
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  //const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
   // chackboxFun();
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

 /*.....income data......*/
 const [income, setIncome] = useState([]);
    useEffect(()=>{
      const selectedCurrency = localStorage.getItem('selectedCurrency');
    	const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
    	if (auth_token !== null) {
    		 axios.post('/apps-income-history',data)
       	 .then(response=>{
           setIncome(response.data.data)
           console.log(response.data.data)
           
			     })
			     .catch(error=>{
			       console.log(error)
			   })
    	}

    },[])





  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title"> {t('pro_IncHis_inc_his')}</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      SL
                    </th>
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      {t('pro_IncHis_type')}
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      {t('pro_IncHis_amount')}
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                       {t('pro_IncHis_date')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                {income.map((incomerow , slg)=>(
                  <tr key={incomerow.id} className="odd" role="row">
                    <td className="sorting_1">{ slg + 1}</td>
                    <td className="sorting_1">{ incomerow.income_Type}</td>
                    <td>{CurrencySymbol + parseFloat(parseFloat(incomerow.income_Amount).toFixed(4)) }</td>
                    <td>{incomerow.date}</td>
                  </tr>
                  ))}
                </tbody>
               
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/income-history"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/income-history"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/income-history"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
