import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { Link ,useHistory} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import { useEffect } from 'react';
//** Import Image */
import profile from "../../../../images/profile/profile.png";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}


  const AppProfile = () => {
  const { CurrencySymbol } = GlobalSet();
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
  const { BaseUrl } = GlobalSet();
    useEffect(()=>{
    	const auth_token = localStorage.getItem('auth_token');
    	const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
    	if (auth_token !== null) {
    		 axios.post('/apps-profile',data)
       	 .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
			     })
			     .catch(error=>{
			       console.log(error)
			   })
    	}

    },[])

	let Email;
	let Username;
	let mobile_No;
	let name;
	let user_type;
	let balance;
	let bonus_balance;
	let total_deposit;
	let total_Income;
	let total_withdraw;
	let affiliate_earning;
	if (user == "") {
		console.log(user);
	}else{

		Email=user.data.email;
		name=user.data.name;
		mobile_No=user.data.mobile_No;
		user_type=user.data.user_type;
		Username=user.data.username;
		balance=user.data.balance;
		bonus_balance=user.data.bonus_balance;
		total_deposit=user.data.total_deposit;
		total_Income=user.data.total_Income;
		total_withdraw=user.data.total_withdraw;
		affiliate_earning=user.data.affiliate_earning;

	}
		
	const options = {
	  settings: {
	    overlayColor: "#000000",
	  },
	};

  const Balance = BaseUrl + "public/appssource/all_icon/Balance.png";
  const Total_Partner = BaseUrl + "public/appssource/all_icon/Total_Partner.png";
  const ref_link = BaseUrl + "public/appssource/all_icon/ref_link.png";
  const today_earning = BaseUrl + "public/appssource/all_icon/today_earning.png";
  const total_profit = BaseUrl + "public/appssource/all_icon/total_profit.png";
  const bonus = BaseUrl + "public/appssource/all_icon/Total_Income.png";
  const total_withdrawal = BaseUrl + "public/appssource/all_icon/total_withdrawal.png";

	const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3">
                    <h4 className="mb-0">Agent Type: {user_type}</h4>
                    <h4 className="mb-0">{t('pro_pg_username')}: {Username}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



						<div className="col-xl-12">
							<div className="row">
                 <div className="col-xl-3 col-xxl-3 col-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
                        <img
                          src={Balance}
                          alt=""
                          width="30px"
                          className="me-3 card-list-img"
                        />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(balance).toFixed(4)) }</h4>
													<p className="mb-0 text-nowrap">{t('pro_pg_curr_bal')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
                 <div className="col-xl-3 col-xxl-3 col-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
                        <img
                          src={bonus}
                          alt=""
                          width="30px"
                          className="me-3 card-list-img"
                        />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ "0"} </h4>
													<p className="mb-0 text-nowrap">{t('pro_pg_bon_inc')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
                 <div className="col-xl-3 col-xxl-3 col-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
                        <img
                          src={total_profit}
                          alt=""
                          width="30px"
                          className="me-3 card-list-img"
                        />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_deposit).toFixed(4)) }</h4>
													<p className="mb-0 text-nowrap ">{t('pro_pg_tot_dep')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
                 <div className="col-xl-3 col-xxl-3 col-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
                        <img
                          src={today_earning}
                          alt=""
                          width="30px"
                          className="me-3 card-list-img"
                        />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_Income).toFixed(4)) }</h4>
													<p className="mb-0">{t('pro_pg_tot_inc')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
                 <div className="col-xl-3 col-xxl-3 col-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
                        <img
                          src={total_withdrawal}
                          alt=""
                          width="30px"
                          className="me-3 card-list-img"
                        />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_withdraw).toFixed(4)) }</h4>
													<p className="mb-0">{t('pro_pg_tot_wit')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



     <div className="row">
        <div className="col-xl-4">

		</div>	
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
					<Tab.Container defaultActiveKey='About'>					
						<Nav as='ul' className="nav nav-tabs mb-4">
							<Nav.Item as='li'i className="nav-item">
								<Nav.Link to="#about-me"  eventKey='About'>{t('pro_pg_ab_me')}</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>

							<Tab.Pane id="about-me" eventKey='About'>
								<div className="profile-personal-info">
									<h4 className="text-primary mb-4">
										Personal Information
									</h4>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_name')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{name}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_username')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{Username}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_mob_num')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{mobile_No}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_email')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{Email}</span>
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>	
					</Tab.Container>		
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
};

export default AppProfile;
