import React, { useState, useRef, useEffect , useReducer} from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { QRCodeCanvas } from "qrcode.react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';


//** Import Image */
const BasicDatatable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { CurrencySymbol } = GlobalSet();
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  //const [test, settest] = useState(0);


  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
   // chackboxFun();
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };



  const user_type = localStorage.getItem('user_type');
  if (!["Sub-Agent"].includes(user_type)) {
   history.push('/dashboard');
   swal("Warning","Permission Not Granted !","warning");
  }

 const [income, setAff] = useState([]);

  const LoadDataRe = () => {
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/user-tr-show',data)
         .then(response=>{
           setAff(response.data.data);
           })
           .catch(error=>{
            swal("error", "Sorry, Not valid this link", "error");
            history.push('/dashboard');
           })
      }
    }
  useEffect(() => {
      LoadDataRe();
  }, []);


  const handleReloadData = () => {
      LoadDataRe();
  };


  const [showForm, setShowForm] = useState(false);
  const [depositInput, setDepositInput] = useState({
    username: '',
    amount: '',
    password: '',
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setDepositInput({ ...depositInput, [e.target.name]: e.target.value });
  }

  const promocodeSubmit = (e) => {
    e.preventDefault();
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      username: depositInput.username,
      amount: depositInput.amount,
      password: depositInput.password,
      currency: selectedCurrency,
    }

    if (auth_token !== null  && !loading) {
      setLoading(true);
      axios.post(`/transfer-ag-usre-balance`, data).then(res => {
        setLoading(false);
        LoadDataRe();
        if (res.data.status === 'success') {
          swal("Success", res.data.message, "success");

          setDepositInput({
            ...depositInput,
            username: '',
            amount: '',
            password: '',
            error_list: []
          });
        } else if (res.data.status === 'fail') {
          swal("Warning", res.data.message, "warning");
        } else {
          setDepositInput({ ...depositInput, error_list: res.data.validation_error });
        }
        setLoading(false);
      });
    }
  }

  const handleShowButtonClick = () => {
    setShowForm(true);
  };

  const handleHideButtonClick = () => {
    setShowForm(false);
  };

  return (
    <div className="col-12">
      <div className="card mb-0">
        <div className="card-header">
          <h4 className="card-title"> Add Balance</h4>
          {!showForm ? (
            <button onClick={handleShowButtonClick} type="button" className="me-2 btn btn-warning btn-rounded">
              <span className="btn-icon-start text-info"><i className="fa fa-plus color-info"></i></span>Add Balance
            </button>
          ) : (
            <button onClick={handleHideButtonClick} type="button" className="me-2 btn btn-warning btn-rounded">
              <span className="btn-icon-start text-info"><i className="fa fa-plus color-info"></i></span>Add Balance
            </button>
          )}
        </div>
        </div>

        <div className="card-body">             
        {!showForm ? (
            ""
          ) : (
            <div>
              <div className="col-xl-12 col-lg-12 mb-3">
                <div className="basic-form">
                  <form onSubmit={promocodeSubmit} className="needs-validation" noValidate="">
                    <div className="row">

                      <div className="col-sm-3">
                        <div className="input-group transparent-append mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Enter username`}
                            id="username"
                            required
                            name="username"
                            onChange={handleInput}
                            value={depositInput.username}
                          />
                        </div>
                        <span className="text-danger">{depositInput.error_list.username}</span>
                      </div>
                    

                      <div className="col-sm-3">
                        <div className="input-group mb-3 input-warning">
                          <input
                            type="number"
                            className="form-control"
                            placeholder={`Enter ${selectedCurrency} Amount`}
                            id="amount"
                            required
                            name="amount"
                            onChange={handleInput}
                            value={depositInput.amount}
                          />
                          <span className="input-group-text">{CurrencySymbol}</span>
                        </div>
                        <span className="text-danger">{depositInput.error_list.amount}</span>
                      </div>



                      <div className="col-sm-3">
                       <div className="input-group transparent-append mb-2">
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleInput} 
                            value={depositInput.password}
                          />
                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >
                          {" "}
                          {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                        
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                          </div> 
                           <span className="text-primary">{depositInput.error_list.password}</span>
                        </div>
                     </div> 

                      <div className="col-sm-3 mt-2 mt-sm-0">
                        <button type="submit" className="btn btn-warning mb-2">
                            {loading ? 'Submitting...' : 'Send Balance'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
     
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{t('pro_TrHis_tr_his')}</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      sl
                    </th>
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      {t('pro_TrHis_amount')}
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                       Username
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                       {t('pro_TrHis_status')}
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                       {t('pro_TrHis_date')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                {income.map((incomerow, index)=>(
                  <tr key={incomerow.id} className="odd" role="row">
                    <td>{index + 1}</td>
                    <td className="sorting_1">{ CurrencySymbol + parseFloat(parseFloat(incomerow.amount).toFixed(4)) }</td>
                    <td>{incomerow.to_user_id_d}</td>
                    <td>{ incomerow.status==="1" ? " Success"  : incomerow.status==="0" ? " failed"  : incomerow.status==="Pending" ? " Pending"  : incomerow.status==="2" ? " Cancel" : incomerow.status }</td>
                    <td>{incomerow.date}</td>
                  </tr>
                  ))}
                </tbody>
               
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/pablic-add-balance"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/pablic-add-balance"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/pablic-add-balance"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        </div>
      </div>
  );
};

export default BasicDatatable;
